.loading-bg {
    position: relative;
    margin-right: 26px;
}

.loading-1 {
    width: 140px;
    height: 140px;
    border: 10px solid transparent;
    border-left-color: #0082c6;
    border-radius: 50%;
    animation-name: loading;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    position:absolute;
}
.loading-2 {
    width: 120px;
    height: 120px;
    border: 10px solid transparent;
    border-left-color: #253776;
    border-radius: 50%;
    animation-name: loading;    
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: revert;
    position:absolute;
}

.loading-img{
    height:90px;
    margin:25px;
    margin-right: 4px;
    margin-top: 26px;
}

@keyframes loading {
    to {transform: rotate(360deg);}
}