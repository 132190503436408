.font {
    padding-top: 20px; 
    display: flex;
    font-size: 54px;
    font-weight: normal;
    color: #0082C6; 
    text-align: center;
}
.logo {
    width: 120px;
    display: flex
}

@media only screen and (max-width: 520px) {
    .font {
        font-size: 32px;
    }
    .logo {
        width: 100px;
    }
}