.notification-div:hover {
  background-color: #264887;
}
/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #264887;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
} */

.notification-icon {
  cursor: pointer;
}

.notification-icon:hover {
  transform: scale(1.1);
}
