/* The Modal (background) */
.lightboxModal {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  outline-style: none;
  box-shadow: none;
  background-color: #000000db;
}

.button-container {
 position: absolute;
 height: 100%;
 width: 100%;
 font-size: 40px;
}


.download-button {
  position: absolute;
  color: white;
  background-color: #383838db;
  border-radius: 5px;
  padding: 5px;
  top: 10px;
  right: 60px;
}

.download-button:hover {
  transform: scale(1.1);
  cursor: pointer
}

.close {
  color: white;
  position: absolute;
  background-color: #383838db;
  border-radius: 5px;
  top: 10px;
right: 10px;
  padding: 5px;
}

.main-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 0;
  width: 80%;
  max-width: 80%;
}

.main-image {
  max-width: 80%;
  -webkit-transition: all 1s ease; /* Safari and Chrome */
  -moz-transition: all 1s ease; /* Firefox */
  -ms-transition: all 1s ease; /* IE 9 */
  -o-transition: all 1s ease; /* Opera */
  transition: all 1s ease;
}

.thumbnail-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: .5%;
}

.thumbnail-image {
  width: 100%;
  height: 75px
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.prev {
  left: 0;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

img.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
